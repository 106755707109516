import classesData from '@/src/router/views/classes/classes.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (classCounts, currentCampusScope) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: 'TOTAL_NO_CLASSES',
          value: classCounts,
          tooltipDescription: !currentCampusScope
            ? classesData.tooltipDescriptionInstitute
            : classesData.tooltipDescriptionCampus,
        },
      ],
    },
  ]
  return stats
}
