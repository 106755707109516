<template>
  <div>
    <ClassFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="setFilterData"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && isEmpty(classList)">
      <NoRecordFound />
    </div>

    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.slice(0, 2)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ $t(`dashboard.${head.dataTableName}`) }}
          </TH>
          <TH
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            Campus
          </TH>
          <TH
            v-for="head in tableHeaders.slice(3)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ $t(`dashboard.${head.dataTableName}`) }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(classItem, index) in classList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])">
            {{ classItem.title }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Description')">
            {{ classItem.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            {{ classItem.campus.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Sections')">
            <BadgeDisplay :options="classItem.sections" label="title" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="classItem"
              :idx="index"
              :action-list="actionList"
              @action="classAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`Pagination${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(classCounts, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
    <ClassModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentClass"
      @toggle="classModalToggle"
    />
    <UIConfirmationModal
      v-if="confirmation"
      heading="DELETE_CLASS"
      message="Are you sure you want to delete "
      button-text="Delete"
      :modal="confirmation"
      :name="currentClass.title"
      remaining-message="class ?"
      @cancel="toggleConfirmationModal"
      @confirm="deactivateClass"
    />
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import Loader from '@components/BaseComponent/Loader.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import ClassFilter from './ClassesFIlter.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@/src/components/TableAction.vue'
import ClassModal from '@/src/router/views/admin/modals/AddClassModal.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { objectDeepCopy, showColumnInDataTable } from '@/src/utils/generalUtil.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import fileMixins from '@/src/mixins/file-mixins'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import { rightBarStats } from '@/src/router/views/classes/classes.util.js'
import classesData from '@/src/router/views/classes/classes.json'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  name: 'Classes',
  components: {
    BadgeDisplay,
    TableAction,
    ClassModal,
    Loader,
    ClassFilter,
    Pagination,
    NoRecordFound,
    UIConfirmationModal,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  page: classesData.page,
  mixins: [generalUtil, fileMixins, scrollMixin],
  data() {
    return {
      forceRender: 0,
      GENERAL_CONSTANTS,
      attendanceTranslation: 'attendanceTranslation',
      dashboard: 'dashboard',
      errorMsg: false,
      options: ['Select'],
      classList: [],
      tableHeaders: classesData.tableHeaders,
      filtersData: {},
      isLoading: false,
      noRecord: false,
      skipForFilterColumns: classesData.skipForFilterColumns,
      actionList: classesData.actionList,
      defaultSelectedColumns: classesData.defaultSelectedColumns,
      currentClass: null,
      showPagination: false,
      filteredRecordLimit: 10,
      classCounts: 0,
      isEmpty,
      confirmation: false,
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      classSections: (state) => state.section.classSections,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.filterRecord()
      },
    },
  },
  /**
   * Mounted Hook
   * @description Description:
   * - Filters the record
   * - Sets the RightBar Content
   */
  mounted() {
    this.setRightBar()
    this.filterRecord()
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    ...mapActions('classes', ['getClassesList', 'deleteClass']),
    showColumnInDataTable,
    setFilterData(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
    },
    /**
     * Class Modal Toggle
     * @param {Boolean} payload
     * @returns {void}
     * @description Toggling the Class's modal
     */
    classModalToggle(payload = false) {
      this.setShowModal()
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
      if (!this.showModal) this.currentClass = null
    },
    /**
     * Filter Record
     * @param {Number} range
     * @returns {void}
     * @description Filtering the record on the basis of range
     */
    filterRecord(range) {
      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getClassesList(payload)
        .then((res) => {
          this.classList = res.records
          this.classCounts = res.meta.total_records
          this.showPagination = this.classCounts > 10
          this.setRightBar(this.classCounts)
        })
        .finally(() => {
          this.noRecord = isEmpty(this.classList)
          this.isLoading = false
        })
    },
    setRightBar(records = 0) {
      this.setRightbarContent({
        header: {
          title: 'Classes',
          buttons: [
            {
              title: 'Add Class',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
            },
          ],
        },
        stats: rightBarStats(this.classCounts, this.currentCampusScope),
      })
    },
    /**
     * Class Action
     * @param {String} action
     * @param {String} current
     * @returns {void}
     * @description Performing edit Class or delete Class action
     */
    classAction(action, current) {
      this.currentClass = current
      switch (action) {
        case 'Edit':
          this.classModalToggle()
          break
        case 'Delete':
          this.toggleConfirmationModal()
          break
      }
    },

    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentClass = null
    },
    deactivateClass() {
      this.deleteClass(this.currentClass).then(() => {
        this.filterRecord()
        let deleteCurrnetClass = this.deleteCurrnetClass()
        this.$store.commit('section/SET_CLASS_SECTIONS', deleteCurrnetClass)
        this.forceRender++
        this.confirmation = false
      })
    },
    deleteCurrnetClass() {
      return this.classSections.filter((classObj) => {
        if (classObj.class_sections && classObj.class_sections.length) {
          return classObj.class_sections[0].class_id != this.currentClass.id
        }
      })
    },
  },
}
</script>
