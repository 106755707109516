<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="CLSS"
      tooltip-title="CLASS_DETAILS"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @cancel="cancelHanlder"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper>
          <UiInput
            v-model.trim="filtersPayload.title"
            class="flex-1"
            title="TITLE"
            type="text"
            placeholder="TITLE"
            :filter="FILTERS_LIST.TITLE"
            @emitQuery="updateQuery"
          />
          <UiSingleSelect
            v-if="!currentCampusScope"
            v-model.trim="filtersPayload.campus_id"
            title="CAMPUSES"
            label="title"
            :options="campuses"
            class="flex-1"
            reduce="id"
            :filter="FILTERS_LIST.CAMPUS"
            @emitQuery="updateQuery"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { mapActions, mapState } from 'vuex'
import { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { getCountFromObject, removeEmptyKeysFromObject } from '@utils/generalUtil'
import filterMixins from '@/src/mixins/filter-mixins.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    TitleWrapper,
    UiSingleSelect,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      FILTERS_LIST,
      filtersCount: 0,
      filtersPayload: {
        title: '',
        campus_id: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope || null,
    }),
    ...mapState('layout', ['campuses']),
  },

  watch: {
    currentCampusScope: {
      handler() {
        this.clearFilters()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
  },
  methods: {
    getFiltersCount() {
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
