<template>
  <ValidationObserver @submit="addNewClass">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-i18n="dashboard">
          <span v-if="edit">Edit Class</span>
          <span v-else>Add Class</span>
        </span>
      </template>
      <template v-slot>
        <div>
          <div>
            <InputFieldWrapper>
              <UiInput
                v-model="classData.title"
                type="text"
                title="CLASS_TITLE"
                placeholder="CLASS_TITLE"
                class="flex-1"
                rules="required"
              />
              <UiInput
                v-model="classData.description"
                type="text"
                name="Description"
                title="Class Description"
                label="Description"
                placeholder="Class Description"
                class="flex-1"
              />
            </InputFieldWrapper>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="classId"
                :title="'CAMPUSES'"
                label="title"
                :options="campuses"
                :clearable="false"
                :disabled="!!currentCampusScope"
                class="flex-1"
                rules="required"
                reduce="id"
              />
            </InputFieldWrapper>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>
              {{ primaryButtonText }}
            </span>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    UiInput,
    ValidationObserver,
    UIButton,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: { type: Object, default: () => null },
  },
  emits: ['toggle'],
  data() {
    return {
      dashboard: 'dashboard',
      classId: '',
      classData: {
        title: '',
        description: '',
      },
    }
  },
  computed: {
    primaryButtonText() {
      return this.edit ? 'Update' : 'Save'
    },
    ...mapState('layout', ['isLoading', 'campuses']),
    ...mapState('instituteCount', ['instituteCount']),
    ...mapState({
      currentCampusScope: (state) => state.layout.currentCampusScope,
    }),
  },
  mounted() {
    if (this.edit) {
      this.classData = {
        title: this.edit.title,
        description: this.edit.description,
      }
      this.classId = this.edit?.campus?.id
    }
    if (this.currentCampusScope) this.classId = this.currentCampusScope?.id
  },
  methods: {
    /**
     * Handle Click
     * @param {String} eventName
     * @returns {void}
     * @description Function Description:
     * - Handling click on the basis of parameter
     * - Emiting toggle to parent component
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
        case 'cancel':
          break
        case 'confirm':
          break
      }
    },
    async addNewClass() {
      try {
        this.classData.campus_id = this.classId
        const data = {
          classData: this.classData,
        }
        if (this.edit) data.id = this.edit.id
        this.$store.commit('layout/IS_LOADING', true)
        await this.addClasses(data).then((res) => {
          this.$store.commit('toast/NEW', {
            type: 'success',
            message: this.edit
              ? `${res?.data?.title} class successfully updated `
              : `${res?.data?.title} class successfully created`,
          })

          this.$store.commit('layout/IS_LOADING', false)
          this.$store.commit('instituteCount/INCREMENT_INSTITUTE_COUNT', 'classes_count')
        })
        this.$emit('toggle', true)
        this.getClassesList()
      } catch (err) {
        this.$store.commit('layout/IS_LOADING', false)
      }
    },
    ...mapActions('classes', ['addClasses', 'getClassesList']),
  },
}
</script>
